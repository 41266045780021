@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;600;700&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  line-height: 1.5;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
::selection {
  background-color: #1328af;
  color: white;
}
a,
input {
  outline: none;
  &::placeholder {
    text-align: center;
  }
  &[type="radio"] {
    margin-right: 20px;
  }
}
textarea {
  &::placeholder {
    text-align: center;
    font-size: 20px;
  }
}
select {
  &:hover {
    cursor: pointer;
  }
}
header {
  padding: 0 30px;
}
footer {
  background-color: #22284d;
  padding-top: 90px;
  .footer__container {
    display: flex;
    justify-content: space-around;
    max-width: 1710px;
    margin: 0 auto 60px 0;
    padding: 0 30px;
    @media (max-width: 468px) {
      padding: 0 15px;
    }
    @media (max-width: 900px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}
section {
  padding: 90px 30px;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #eeeff7;
}
button {
  cursor: pointer;
}
img {
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -o-user-select: none;
  user-select: none;
}
.hero {
  margin: 0;
  padding: 0;
}
.altr {
  background-color: transparent;
}
.p2 {
  padding: 15px 20px;
}
.top {
  height: 40px;
  width: 100%;
  background-color: black;
}
/* MISCELLANEOUS STYLES */
.title {
  margin-bottom: 60px;
  text-align: center;
  text-transform: capitalize;
}
.title__heading {
  font-size: 35px;
  font-weight: bold;
}
.title__heading-2 {
  font-size: 24px;
  font-weight: bold;
}
.container {
  max-width: 1300px;
  margin: 0 auto;
}
.error {
  text-align: center;
  & h2 {
    padding-bottom: 30px;
  }
}
.back-to-top {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.go-to-blog {
  height: 15px;
  margin-left: 5px;
}
.small-img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.5s all ease;
}
.small-img:hover {
  transform: scale(1.1);
}
.portfolio__link {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.contact-links {
  display: flex;
  margin-top: 60px;
}
.item-text {
  font-size: 22px;
  line-height: 2;
}
.item-text a {
  color: #1328af;
}
.graphics-list-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;
  margin-bottom: -15px;
  margin-top: 15px;
}
.graphics-list-grid .list-item {
  font-size: 26px;
  margin: 0 0 15px 18px;
  list-style: disc;
}
/* PORTFOLIO SECTION */ 
.portfolio-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, auto));
  gap: 40px;
}
.portfolio-images.first {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, auto));
  gap: 40px;
  margin-bottom: 60px;
}
/* HAMBURGER BUTTON */
button.hamburger-react {
  height: 50px;
  width: 50px;
}

div.hamburger-react {
  height: 50px;
  width: 50px;
}

div.hamburger-react > .inner {
  top: 50%;
}

.hamburger-react {
  background-color: rgba(0, 0, 0, 0);
  border: 0 rgba(0, 0, 0, 0) solid;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  opacity: 1;
  overflow: visible;
  padding: 5px;
  text-transform: none;
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger-react:hover {
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
}

.hamburger-react:hover span.bar {
  background-color: #eeeff7;
}

.hamburger-react:hover > .inner:nth-child(1)::before,
.hamburger-react:hover > .inner:nth-child(1)::after,
.hamburger-react:hover > .inner:nth-child(2)::before,
.hamburger-react:hover > .inner:nth-child(2)::after,
.hamburger-react:hover > .inner:nth-child(3)::before,
.hamburger-react:hover > .inner:nth-child(3)::after,
.hamburger-react:hover span.bar:nth-child(1)::before,
.hamburger-react:hover span.bar:nth-child(1)::after,
.hamburger-react:hover span.bar:nth-child(2)::before,
.hamburger-react:hover span.bar:nth-child(2)::after,
.hamburger-react:hover span.bar:nth-child(3)::before,
.hamburger-react:hover span.bar:nth-child(3)::after {
  background-color: #eeeff7;
}

.hamburger-react.active {
  background-color: rgba(0, 0, 0, 0);
}

.hamburger-react.active span.bar {
  background-color: #eeeff7;
}

.hamburger-react.active > .inner:nth-child(1)::before,
.hamburger-react.active > .inner:nth-child(1)::after,
.hamburger-react.active > .inner:nth-child(2)::before,
.hamburger-react.active > .inner:nth-child(2)::after,
.hamburger-react.active > .inner:nth-child(3)::before,
.hamburger-react.active > .inner:nth-child(3)::after,
.hamburger-react.active span.bar:nth-child(1)::before,
.hamburger-react.active span.bar:nth-child(1)::after,
.hamburger-react.active span.bar:nth-child(2)::before,
.hamburger-react.active span.bar:nth-child(2)::after,
.hamburger-react.active span.bar:nth-child(3)::before,
.hamburger-react.active span.bar:nth-child(3)::after {
  background-color: #eeeff7;
}

.hamburger-react > .inner {
  margin: 0;
  margin-top: -1px;
  /* position: relative; */
  width: 100%;
}

.hamburger-react span.bar {
  background-color: #eeeff7;
  border-radius: 0;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  width: 100%;
}

.hamburger-react span.bar:nth-child(1) {
  top: -10px;
}

.hamburger-react span.bar:nth-child(3) {
  top: 10px;
}

.hamburger-react:focus {
  outline: 0;
}

.hamburger-react--criss-cross > .inner::before,
.hamburger-react--criss-cross > .inner::after {
  background-color: #eeeff7;
  border-radius: 0;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  width: 100%;
  opacity: 0;
  transform-origin: 0 50%;
  transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger-react--criss-cross > .inner::before {
  transform: rotate(45deg) translate3d(0px, -20px, 0) scaleX(0);
}

.hamburger-react--criss-cross > .inner::after {
  transform: rotate(-45deg) translate3d(0px, 20px, 0) scaleX(0);
  transition-delay: 0.1s;
}

.hamburger-react--criss-cross span.bar {
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger-react--criss-cross span.bar:nth-child(1) {
  transition-delay: 0.33s;
}

.hamburger-react--criss-cross span.bar:nth-child(2) {
  transition-delay: 0.36s;
}

.hamburger-react--criss-cross span.bar:nth-child(3) {
  transition-delay: 0.39s;
}

.hamburger-react--criss-cross.active > .inner::before,
.hamburger-react--criss-cross.active > .inner::after {
  opacity: 1;
}

.hamburger-react--criss-cross.active > .inner::before {
  transform: rotate(45deg) translate3d(0px, -20px, 0);
  transition-delay: 0.4s;
}

.hamburger-react--criss-cross.active > .inner::after {
  transform: rotate(-45deg) translate3d(0px, 20px, 0);
  transition-delay: 0.5s;
}

.hamburger-react--criss-cross.active span.bar {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
  width: 50%;
}

.hamburger-react--criss-cross.active span.bar:nth-child(1) {
  transition-delay: 0s;
}

.hamburger-react--criss-cross.active span.bar:nth-child(2) {
  transition-delay: 0.2s;
}

.hamburger-react--criss-cross.active span.bar:nth-child(3) {
  transition-delay: 0.1s;
}

.hamburger-react {
  display: none;
  z-index: 6;
  position: absolute;
  right: 30px;
  @media (max-width: 1280px) {
    display: block;
  }
}

.hamburger-react.fixed {
  position: fixed;
}

/* MOBILE MENU */
.mobile__container {
  display: none;
  @media (max-width: 1100px) {
    display: block;
  }
}
.mobile__swipe {
  background-color: #1328af;
  width: 240px;
  padding: 0 30px;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 4;
  transform: translateX(245px);
}
.mobile__menu {
  background-color: black;
  width: 230px;
  padding: 0 30px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 4;
  overflow-y: scroll;
  transform: translateX(230px);
}
.mobile__menu .mobile__links {
  display: flex;
  flex-direction: column;
  margin-top: 145px;
}
.mobile__menu .mobile__links a {
  padding: 10px;
}

/* SCREEN WIDTH RESPONSIVENESS */
@media screen and (max-width: 320px) {
  .contact__details {
    padding: 15px 15px 40px 15px;
  }
}
@media screen and (max-width: 376px) {
  header {
    padding-bottom: 90px;
    height: auto;
  }
  .header__container {
    margin-top: 30px;
    height: auto;
  }
  .header__container .header__info h1 {
    font-size: 40px;
  }
  .form-for-quote {
    padding: 30px 15px;
  }
  .tab button {
    width: 100%;
    border-bottom: 1px solid #ccc;
  }
  button {
    font-size: 16px;
  }
  .order-form {
    padding: 60px 15px;
  }
  .social__link__container {
    margin-left: 30px;
  }
}
@media screen and (max-width: 405px) {
  .discount {
    margin: 15px 0 0 0;
  }
  .render-text {
    max-height: 700px;
  }
}
@media screen and (max-height: 411px) and (orientation: landscape) {
  .order-form {
    height: 310px;
    width: 90vw;
    padding: 15px;
  }
  .order-form .close-btn {
    height: 25px;
    top: 30px;
    right: 30px;
  }
  .order-form input {
    padding: 7px;
    margin-bottom: 15px;
  }
  .order-form .button {
    padding: 7px;
  }
}
@media screen and (max-width: 468px) {
  .hamburger-react {
    right: 15px;
  }
  section,
  header {
    padding-left: 15px;
    padding-right: 15px;
  }
  .fabrication {
    padding-bottom: 60px;
  }
  .software {
    padding-top: 60px;
    padding-bottom: 90px;
  }
  .digital {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .item__info__container {
    grid-template-columns: repeat(auto-fit, minmax(90vw, 1fr));
  }
  .item__info__container .item {
    width: 90vw;
  }
  .item__info__container .item img {
    width: calc(90vw - 2px);
    margin-left: 0px;
  }
  .order-form {
    width: 90vw;
  }
  .render img {
    height: 230px;
    object-fit: contain;
  }
  .small-img:hover {
    transform: scale(1.05);
  }
  .footer__container {
    padding: 0 15px;
  }
  .footer__container .footer__title {
    font-size: 22px;
  }
  .footer__links__container {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .counter__info:nth-child(1) {
    margin-left: 0px;
  }
  .counter {
    text-align: center;
    padding: 60px 15px;
  }
  .counter__info__container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    gap: 30px;
  }
}
@media screen and (max-width: 532px) {
  .contact__links .portfolio {
    margin: 0 0 0 15px;
    height: auto;
  }
  .item-image {
    object-fit: cover;
  }
}
@media screen and (max-width: 568px) and (orientation: landscape) {
  header {
    min-height: 540px;
  }
  .header__container {
    margin-top: 90px;
  }
}
@media screen and (max-width: 580px) {
  header {
    padding-bottom: 90px;
    height: auto;
  }
  .header__container {
    margin-top: 60px;
    height: auto;
  }
  .header__container .header__info p {
    font-size: 20px;
  }
  .header__container .header__info h1 {
    font-size: 45px;
  }
  .header__container .header__info .header__info__contact {
    width: 280px;
  }
  .order {
    flex-wrap: wrap;
  }
  .order img {
    margin-left: 0px;
    margin: 50px auto 0 auto;
    justify-self: center;
  }
  .quote-forms {
    gap: 40px;
  }
  .order-form {
    width: 80vw;
  }
}
@media screen and (max-width: 590px) {
  .item__info__container {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  .item__info__container .item {
    width: 350px;
  }
  .item__info__container .item img {
    width: 349px;
    margin-left: -0.5px;
  }
}
@media screen and (max-width: 667px) {
  .service__heading {
    font-size: 35px;
    text-align: center;
  }
  .services__grid div {
    padding: 30px 15px;
  }
  .social__link img {
    height: 40px;
  }
  .contact__details {
    padding: 30px 30px 60px 30px;
    width: 90vw;
    height: auto;
    min-height: auto;
  }
  .contact__details h3 {
    display: none;
  }
  iframe {
    height: 458px;
    width: 90vw;
  }
  .header__container .header__info h1 {
    font-size: 35px;
  }
  .item-text {
    font-size: 16px;
  }
  .graphics-list-grid {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: -10px;
  }
  .list-item {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .item-image {
    height: 300px;
  }
}
@media screen and (max-width: 680px) and (orientation: landscape) {
  header {
    min-height: 500px;
  }
  .header__container {
    margin-top: 60px;
  }
}
@media screen and (max-width: 691px) {
  .item__info__container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .item__info__container .item {
    width: 250px;
  }
  .item__info__container .item img {
    width: 250px;
    margin-left: -1.5px;
  }
}
@media screen and (max-width: 725px) and (orientation: landscape) {
  header {
    min-height: 552px;
  }
}
@media screen and (max-width: 768px) {
  section {
    padding-bottom: 60px;
  }
  .about__info {
    flex-wrap: wrap;
  }
  .about__info p {
    margin-right: 0;
    margin-bottom: 30px;
    width: 100%;
  }
  .achievements__info__container {
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  }
  .achievements__info {
    padding: 30px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
      url("./assets/images/services/engraving.webp");
  }
  .form__container {
    width: 90vw;
  }
  .form__container .contact__input {
    grid-template-columns: 90vw;
    grid-template-rows: 45px 45px 45px 45px;
    gap: 20px 30px;
  }
  .form__container textarea {
    width: 90vw;
  }
  .form__container .submit-button {
    width: 90vw;
  }
  .pricing-grid {
    flex-direction: column;
    align-content: center;
  }
  .pricing-grid-item {
    margin-top: 0px;
  }
  .pricing-grid-item:hover {
    transform: none;
  }
  .pricing-grid-item.recommended-item:hover {
    transform: none;
  }
  .guide-section {
    display: block;
  }
  .blog-card {
    flex-direction: column;
    width: 100%;
    min-height: 100px;
  }
  .blog-card .main-img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
}
@media screen and (max-width: 824px) and (orientation: landscape) {
  header {
    min-height: 512px;
  }
  .header__container .header__info {
    max-width: 800px;
  }
  .header__container .header__info h1 {
    font-size: 45px;
  }
}
@media screen and (max-width: 855px) {
  .filler {
    display: none;
  }
  .item__info__container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .item__info__container .item {
    width: 300px;
  }
}
@media screen and (max-width: 890px) {
  .grid-card .pricing-features .explanation:hover > .explain-info {
    position: relative;
    left: 0;
    transform: translate(0%, 0);
    top: 0;
    margin-top: 15px;
    width: 250px;
  }
}
@media screen and (max-width: 900px) {
  .counter {
    height: 100%;
  }
  .counter__info__container {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    gap: 60px 30px;
  }
  .counter__info:nth-child(1) {
    margin-left: -30px;
  }
  .form-for-quote {
    padding: 15px;
  }
  .quote-forms {
    display: none;
  }
  .order img {
    height: 243px;
    margin-right: 0px;
    margin-left: 30px;
  }
  .order-form {
    width: 60vw;
    min-height: 320px;
  }
  .contact__info {
    grid-template-columns: auto;
    grid-template-rows: auto 435px;
    gap: 30px;
  }
  .contact__details {
    padding: 60px;
    width: 70vw;
    height: auto;
  }
  iframe {
    height: 458px;
    width: 70vw;
    margin-top: 30px;
  }
  .footer__container {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .footer__links__container {
    justify-content: space-between;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
    flex-wrap: wrap;
  }
  .footer__links__container .footer__links {
    margin-top: 30px;
  }
}
@media screen and (max-width: 900px) and (orientation: landscape) {
  header {
    height: 95vh;
  }
  .header__info {
    margin-top: 80px;
  }
  .order-form {
    width: 60vw;
    height: 490px;
  }
}
@media screen and (max-width: 985px) {
  .contact__details {
    width: 400px;
    height: 528px;
    padding: 30px;
  }
  iframe {
    height: 528px;
    width: 400px;
  }
}
@media screen and (max-width: 1005px) {
  .item__info__container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .item__info__container .item {
    width: 250px;
  }
}
@media screen and (max-width: 1100px) {
  .nav__links__container {
    display: none;
  }
  .hamburger,
  .mobile__container {
    display: block;
  }
  .services__grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    justify-items: center;
    grid-template-areas:
      "a"
      "b"
      " c";
  }
  .services__fabtrication {
    height: 100%;
  }
  .services__graphics {
    height: 100%;
  }
  .services__software {
    height: 100%;
  }
  .render {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    margin-top: 40px;
    max-width: 100%;
    grid-template-areas:
      "a"
      "b"
      "c";
  }
  .render img {
    height: 400px;
    object-fit: fill;
  }
  .item__info__container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .item__info__container .item {
    width: 300px;
  }
  .item-buttons .order {
    margin-bottom: 15px;
  }
  .item-image {
    height: 400px;
  }
  .contact__details {
    padding: 60px 30px 60px 30px;
    width: 450px;
    height: 558px;
  }
  iframe {
    width: 450px;
    height: 558px;
  }
  .footer__links__container .footer__links {
    margin-right: 30px;
  }
}
@media screen and (max-width: 1135px) {
  .nav__links__container .nav__links li > a {
    padding: 0 10px;
  }
}
@media screen and (max-width: 1200px) {
  .service__heading {
    font-size: 40px;
  }
}
@media screen and (max-width: 1226px) {
  .item__info__container {
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
  .item__info__container .item {
    width: 330px;
  }
}
@media screen and (max-width: 1236px) {
  .pricing-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
