/* HEADER SECTION */
.top {
  height: 40px;
  width: 100%;
  background-color: black;
}
header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./assets/images/webp/hero.webp");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding: 0 30px;
  @media (max-width: 376px) {
    padding-bottom: 90px;
    height: auto;
  }
  @media (max-width: 468px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 568px) and (orientation: landscape) {
    min-height: 540px;
  }
  @media (max-width: 580px) {
    padding-bottom: 90px;
    height: auto;
  }
  @media (max-width: 680px) and (orientation: landscape) {
    min-height: 500px;
  }
  @media (max-width: 725px) and (orientation: landscape) {
    min-height: 552px;
  }
  @media (max-width: 824px) and (orientation: landscape) {
    min-height: 512px;
  }
  @media (max-width: 900px) and (orientation: landscape) {
    height: 95vh;
  }
}
nav {
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.13);
}
.nav__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
}
.nav__links__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1280px) {
    display: none;
  }
}
.logo {
  margin-right: 30px;
  margin-top: 20px;
  height: 100px;
  transition: 0.4s ease-out;
  @media (max-width: 900px) {
    height: 70px;
    margin-top: 20px;
    /* margin-left: 20px;
    margin-top: 20px; */
  }
}
.nav__links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
  & li > a {
    padding: 0px 15px;
    font-size: 20px;
    position: relative;
    @media (max-width: 1135px) {
      padding: 0 10px;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #eeeff7;
      transform-origin: bottom right;
      transform: scaleX(0);
      transition: transform 0.5s ease;
    }
    &:hover::before {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
}
.header__container {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  margin: 0 30px;
  height: calc(100vh - 180px);
  color: #eeeff7; */
  @media (max-width: 376px) {
    margin-top: 30px;
    height: auto;
  }
  @media (max-width: 568px) and (orientation: landscape) {
    margin-top: 90px;
  }
  @media (max-width: 580px) {
    margin-top: 60px;
    height: auto;
  }
  @media (max-width: 680px) and (orientation: landscape) {
    margin-top: 60px;
  }
  @media (max-width: 900px) {
    margin: 30px 0 0;
  }
}
.header__info {
  max-width: 800px;
  /* @media (max-width: 824px) and (orientation: landscape) {
    max-width: 800px;
  } */
  @media (max-width: 900px) and (orientation: landscape) {
    margin-top: 80px;
  }
  & p {
    font-size: 28px;
    color: rgba(238, 239, 247, 0.75);
    @media (max-width: 580px) {
      font-size: 20px;
    }
  }
  & h1 {
    font-size: 65px;
    font-weight: bold;
    @media (max-width: 376px) {
      font-size: 40px;
    }
    @media (max-width: 580px) {
      font-size: 45px;
    }
    @media (max-width: 667px) {
      font-size: 35px;
    }
    @media (max-width: 824px) and (orientation: landscape) {
      font-size: 45px;
    }
  }
  & .header__info__contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    margin-top: 30px;
    @media (max-width: 580px) {
      width: 280px;
    }
  }
}
.button {
  z-index: 1;
  position: relative;
  color: #eeeff7;
  font-size: 20px;
  border-radius: 5px;
  padding: 10px 15px;
  outline: none;
  border: none;
  background-color: #1328af;
  transition: all 0.3s ease-in-out;
  @media (max-width: 900px) {
    font-size: 16px;
  }
  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    right: 0;
    border: 4px solid rgba(23, 47, 184, 0.295);
    transform-origin: center;
    transform: scale(1);
  }
  &:hover {
    background-color: rgba(19, 40, 175, 0.659);
    &::before {
      transition: all 1s ease-in-out;
      transform-origin: center;
      transform: scale(1.4);
      opacity: 0;
    }
  }
}
.button-svg {
  width: 30px;
  margin-left: 7px;
  margin-right: 7px;
  background: transparent;
}
/* ABOUT SECTION */
.about {
  background: white;
}
.container {
  max-width: 1600px;
  margin: 0 auto;
}
.title {
  margin-bottom: 60px;
  text-align: center;
  text-transform: capitalize;
  & .title__heading {
    font-size: 50px;
    font-weight: bold;
    @media (max-width: 900px) {
      font-size: 35px;
    }
  }
}
.about__info__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  gap: 60px 30px;
}
.about__info__graphic {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(34, 40, 77, 0.267);
  transition: 0.5s all ease;
  &:hover {
    border-color: rgba(34, 40, 77, 0.9);
  }
  & img {
    height: 150px;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px;
  }
  & .about__title {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 24px;
  }
  & .about__desc {
    font-size: 20px;
    opacity: 0.9;
    font-family: "Karla";
  }
}
/* SERVICES SECTION */
.service {
  background-color: #eeeff7;
  min-height: 100%;
  & .services__grid {
    display: grid;
    min-height: 600px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 30px;
    grid-template-areas:
      "a b"
      "a c";
    overflow: hidden;
    @media (max-width: 1100px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
      justify-items: center;
      grid-template-areas:
        "a"
        "b"
        "c";
    }
    & div {
      border-radius: 10px;
      box-shadow: 0 35px 50px -30px rgba(0, 0, 0, 0.2);
      transition: 0.5s all ease;
      display: flex;
      padding: 30px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 667px) {
        padding: 30px 15px;
      }
    }
    & .service__heading {
      font-size: 50px;
      color: #eeeff7;
      font-weight: bold;
      @media (max-width: 667px) {
        font-size: 35px;
        text-align: center;
      }
      @media (max-width: 1200px) {
        font-size: 40px;
      }
    }
    & .service__info {
      margin: 15px 0 30px 0;
      color: rgba(238, 239, 247, 0.75);
      text-align: center;
    }
  }
}
.item__info__container {
  max-width: 95%;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(373px, 1fr));
  gap: 60px 15px;
  @media (max-width: 468px) {
    grid-template-columns: repeat(auto-fit, minmax(90vw, 1fr));
  }
  @media (max-width: 590px) {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  @media (max-width: 691px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  @media (max-width: 855px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    gap: 60px 30px;
  }
  @media (max-width: 1005px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: auto auto auto;
    justify-items: center;
    grid-template-areas:
      "a"
      "b"
      "c";
  }
  @media (max-width: 1226px) {
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
  & .item {
    width: auto;
    margin: 0 auto;
    border: 1px solid #090f32;
    border-radius: 5px;
    @media (max-width: 468px) {
      width: 90vw;
    }
    @media (max-width: 590px) {
      width: 350px;
    }
    @media (max-width: 691px) {
      width: 250px;
    }
    @media (max-width: 855px) {
      width: 300px;
    }
    @media (max-width: 1005px) {
      width: 250px;
    }
    @media (max-width: 1100px) {
      width: 300px;
    }
    @media (max-width: 1226px) {
      width: 330px;
    }
    img {
      @media (max-width: 468px) {
        width: calc(90vw - 2px);
        margin-left: 0px;
      }
      @media (max-width: 590px) {
        width: 349px;
        margin-left: -0.5px;
      }
      @media (max-width: 691px) {
        width: 250px;
        margin-left: -1.5px;
      }
    }
  }
}
.service__fabrication {
  grid-area: a;
  width: auto;
  height: 600px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./assets/images/webp/engraving.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 468px) {
    padding-bottom: 60px;
    height: auto;
  }
}
.service__graphics {
  grid-area: b;
  height: calc(300px - 15px);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/images/webp/graphics.webp");
  @media (max-width: 468px) {
    padding-top: 60px;
    padding-bottom: 60px;
    height: auto;
  }
}
.service__software {
  grid-area: c;
  height: calc(300px - 15px);
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/images/webp/software.webp");
  @media (max-width: 468px) {
    padding-top: 60px;
    padding-bottom: 90px;
    height: auto;
  }
}
/* COUNTER SECTION */
.counter {
  height: 200px;
  padding: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #090f32;
  @media (max-width: 500px) {
    text-align: center;
    padding: 60px 15px;
  }
  @media (max-width: 900px) {
    height: 100%;
  }
  & .counter__info__container {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 0 100px;
    align-content: center;
    justify-items: center;
    color: #eeeff7;
    width: 1200px;
    @media (max-width: 500px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, auto);
      gap: 30px;
    }
    @media (max-width: 900px) {
      grid-template-columns: repeat(1, auto);
      grid-template-rows: repeat(1, auto);
      gap: 60px 30px;
    }
  }
  & .counter__info {
    position: relative;
    text-align: center;
    &:nth-child(1) {
      @media (max-width: 500px) {
        margin-left: 0px;
      }
      /* @media (max-width: 900px) {
        margin-left: -30px;
      } */
    }
  }
  & .counter__number {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  & .counter__desc {
    font-size: 20px;
  }
}
/* TESTIMONY SECTION */
.testimony__section {
  background-color: white;
  margin-top: 100px;
}

.testimony__container {
  max-width: 1300px;
  margin: 0 auto;
}

.testimony__title {
  margin-bottom: 60px;
  text-align: center;
  text-transform: capitalize;
}

.testimony__title__heading {
  font-size: 35px;
  font-weight: bold;
}

.testimony__card {
  margin: 0 auto;
  max-width: 900px;
  height: auto;
  text-align: center;
  padding: 0 30px;
}

.testimony__card .testimony__rating {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimony__card .testimony__rating .star {
  height: 30px;
  margin: 0 5px;
}

.testimony__card .testimony__img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin-top: 15px;
}

.testimony__card .testimony__name {
  font-size: 28px;
  font-weight: bold;
}

.testimony__card .testitmony__title {
  font-size: 26px;
  opacity: 0.9;
  margin: 5px 0 0 0;
  opacity: 0.6;
}

.testimony__card .testimony__text {
  font-size: 24px;
  margin-bottom: 15px;
}

/* INSIGHT SECTION */
.accordion {
  background-color: #eeeff7;
  color: black;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  border-bottom: 1px solid #090f32;
  outline: none;
  font-size: 22px;
  transition: 0.2s ease;
  &:hover {
    background-color: #6374e6;
    color: #eeeff7;
  }
  &:after {
    content: "\02795";
    font-size: 16px;
    color: red;
    float: right;
    margin-left: 5px;
  }
}
.acc-active:after {
  content: "\2796";
}
.panel {
  padding: 0;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  & p {
    padding: 15px 18px;
    font-size: 18px;
  }
  & a {
    color: #1328af;
  }
}
/* ACHIEVEMENTS SECTION */
.achievements__info__container {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  gap: 30px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  }
}
.achievements__info {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./assets/images/services/engraving.webp");
  height: 100%;
  padding: 30px;
  color: #eeeff7;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  transition: 0.5s all ease;
  @media (max-width: 768px) {
    padding: 30px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
      url("./assets/images/services/engraving.webp");
  }
}
.achievements__text {
  font-size: 22px;
  @media (max-width: 900px) {
    font-size: 20px;
  }
}
/* SERVICES PAGE: PRICING SECTION */
.pricing-grid {
  display: grid;
  grid-template-columns: 1fr 1.1fr 1fr;
  gap: 30px;
  margin-top: 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-content: center;
  }
  @media (max-width: 1236px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.pricing-grid-item {
  margin-top: 35px;
  transition: all 0.5s ease-in-out;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
}
.pricing-grid-item:hover {
  transform: scale(1.05);
  @media (max-width: 768px) {
    transform: none;
  }
}
.pricing-grid-item.recommended-item:hover {
  transform: scale(1.05);
  @media (max-width: 768px) {
    transform: none;
  }
}
.pricing-grid-card {
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 30px 25px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 26px 0px;
}
.pricing-grid-card-heading {
  text-align: center;
}
.pricing-grid-card-title {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: bold;
}
.pricing-grid-card-sub-title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.pricing-features {
  margin-top: 30px;
}
/* ABOUT PAGE: GENERAL SECTION */
.about__container {
  max-width: 1600px;
  margin: 0 auto;
}
.about__title {
  margin-bottom: 60px;
  text-align: center;
  text-transform: capitalize;
}
.about__title__heading {
  font-size: 50px;
  font-weight: bold;
}
.about__info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: -24px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}
.about__text {
  margin-right: 60px;
  width: 1100px;
  font-size: 22px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 30px;
    width: 100%;
  }
}
.about__img {
  width: 260px;
}
/* BLOG SECTION */
.blog-post:hover {
  transform: scale(1.05);
}
.blog-section p {
  font-size: 20px;
}
.blog-info {
  margin-bottom: 60px;
}
.blog-title {
  margin-bottom: 20px;
  text-align: left;
}
.blog-title-heading-2 {
  font-size: 24px;
  font-weight: bold;
}
.blog-text {
  margin-top: 30px;
  &:nth-child(2) {
    margin-top: 0;
  }
}
.citation {
  margin-top: 90px;
  & p {
    margin-bottom: 15px;
    opacity: 0.9;
    font-size: 18px;
    word-break: break-all;
  }
}
/* CONTACT PAGE */
.contact__section {
  max-width: 1300px;
  margin: 0 auto;
}
.contact__title {
  margin-bottom: 60px;
  text-align: center;
  text-transform: capitalize;
}
.contact__title__heading {
  font-size: 35px;
  font-weight: bold;
}
.contact__details {
  background-color: #eeeff7;
  width: 500px;
  padding: 60px;
  min-height: 457px;
  @media (max-width: 320px) {
    padding: 15px 15px 40px 15px;
  }
  @media (max-width: 667px) {
    padding: 30px 30px 60px 30px;
    width: 90vw;
    height: auto;
    min-height: auto;
  }
  @media (max-width: 900px) {
    padding: 60px;
    width: 70vw;
    height: auto;
  }
  @media (max-width: 985px) {
    width: 400px;
    height: 528px;
    padding: 30px;
  }
  @media (max-width: 1100px) {
    padding: 60px 30px 60px 30px;
    width: 450px;
    height: 558px;
  }
}
.contact__details h3 {
  font-size: 30px;
  @media (max-width: 667px) {
    display: none;
  }
}
.contact__info {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto;
  grid-template-rows: 524px;
  gap: 0;
  @media (max-width: 900px) {
    grid-template-columns: auto;
    grid-template-rows: auto 435px;
    gap: 30px;
  }
}
.contact__list {
  margin-top: 30px;
  @media (max-width: 360px) {
    margin: 0 auto;
    width: 85%;
  }
}
.contact__list li {
  margin-top: 20px;
  & a {
    @media (max-width: 360px) {
      font-size: 20px;
    }
  }
}
.contact__list__item {
  display: flex;
}
.contact__list__item img {
  height: 70px;
}
.contact__list__item .mail {
  height: 50px;
  margin-top: 15px;
  margin-right: 15px;
}
.contact__list__item .clock {
  height: 55px;
  margin-top: 20px;
  margin-right: 15px;
}
.contact__list__item div {
  margin-top: 12px;
}
.contact__list__item .m-5 {
  margin-left: 5px;
}
.contact__link {
  color: #1328af;
  font-weight: bold;
  font-size: 22px;
  word-break: break-all;
}
.social__link__container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.social__link img {
  height: 30px;
  margin-right: 30px;
  transition: 0.5s all ease;
}
.social__link:last-child img {
  height: 40px;
  margin: -5px;
}
.social__link img:hover {
  transform: scale(1.2);
}
.social__link:nth-child(1) {
  transform: scale(1.2);
}
iframe {
  width: 500px;
  height: 524px;
  margin-top: 7px;
  @media (max-width: 667px) {
    height: 458px;
    width: 90vw;
  }
  @media (max-width: 900px) {
    height: 458px;
    width: 70vw;
    margin-top: 30px;
  }
  @media (max-width: 985px) {
    height: 528px;
    width: 400px;
  }
  @media (max-width: 1100px) {
    width: 450px;
    height: 558px;
  }
}
.form__title {
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
  text-transform: capitalize;
}
.form__title__heading {
  font-size: 35px;
  font-weight: bold;
}
.contact__form {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.form__container {
  width: calc((350px * 2) + 34px);
  position: relative;
  @media (max-width: 768px) {
    width: 90vw;
  }
}
.form__container .status {
  background-color: red;
  border-radius: 5px;
  height: 40px;
  margin-bottom: 15px;
  position: absolute;
  top: -50px;
  display: flex;
}
.form__container .status-icon {
  background-color: green;
  height: 20px;
  width: 20px;
}
.form__container .form__contact__input {
  display: grid;
  grid-template-columns: 350px 350px;
  grid-template-rows: 45px 45px;
  gap: 20px 30px;
  @media (max-width: 768px) {
    grid-template-columns: 90vw;
    grid-template-rows: 45px 45px 45px 45px;
    gap: 20px 30px;
  }
}
.form__container .form__contact__input input,
.form__container .form__contact__input select {
  padding: 0 30px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.288);
  border-radius: 5px;
  appearance: none;
  text-align: center;
  text-align-last: center;
}
.form__container .form__contact__input input:required,
.form__container .form__contact__input select:required {
  box-shadow: none;
}
.form__container .form__contact__input input.warning::placeholder {
  color: red;
}
.form__container textarea {
  width: 100%;
  height: 180px;
  margin-top: 20px;
  padding: 15px 30px;
  border: 1px solid rgba(0, 0, 0, 0.288);
  border-radius: 5px;
  outline: none;
  resize: none;
  margin-bottom: -6px;
  @media (max-width: 768px) {
    width: 90vw;
  }
}
.form__container textarea::placeholder {
  text-align: center;
  text-align-last: center;
}
.form__container .submit-button {
  z-index: 1;
  margin-top: 20px;
  position: relative;
  color: #eeeff7;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px 15px;
  outline: none;
  border: none;
  width: 100%;
  background-color: #1328af;
  transition: all 0.3s ease-in-out;
  @media (max-width: 768px) {
    width: 90vw;
  }
}
.form__container .submit-button.success {
  background-color: #13af20;
}
.form__container .submit-button::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  right: 0;
  border: 4px solid rgba(23, 47, 184, 0.295);
  transform-origin: center;
  transform: scale(1);
}
.form__container .submit-button:hover::before {
  transition: all 1s ease-in-out;
  transform-origin: center;
  transform: scale(1.03);
  opacity: 0;
}
/* INSIGHT PAGE */
.insight__container {
  max-width: 1600px;
  margin: 0 auto;
}
.insight__title {
  margin-bottom: 60px;
  text-align: center;
  text-transform: capitalize;
}
.insight__title__heading {
  font-size: 50px;
  font-weight: bold;
  @media (max-width: 900px) {
    font-size: 35px;
  }
}
.insight__faq__container {
  max-width: 1600px;
  margin: 0 auto;
}
/* ORDER PAGE */
.quote-forms {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 40px;
}
.quote-form-title {
  background-color: #eeeff7;
  display: block;
  transition: 0.3s;
  text-align: center;
  padding: 14px 16px;
  font-size: 22px;
}
.form-for-quote {
  border: 1px solid #ccc;
  padding: 15px 15px 30px 15px;
  height: auto;
}
.quote-select {
  font-size: 22px;
  padding-bottom: 7.5px;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
}
.quote-span {
  font-size: 16px;
}
.quote-service-title {
  text-transform: capitalize;
  font-size: 22px;
}
/* TEAM PAGE */
.team {
  margin: 0 auto;
}
.team__info__container {
  /* max-width: 1300px; */
  /* margin: 0 auto; */
  display: grid;
  justify-items: left;
  grid-template-columns: repeat(auto-fit, minmax(360px, 0fr));
  gap: 60px;
}
.team__item {
  width: 330px;
  border: 1px solid #090f32;
  border-radius: 5px;
  & img {
    height: 250px;
    width: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    object-fit: cover;
  }
}
.team__item__info {
  padding: 15px 15px 30px 15px;
}
.team__item__text {
  padding-right: 15px;
}
.team__item__header {
  margin-top: 9px;
  font-weight: bold;
  font-size: 25px;
}
.team__item__description {
  margin: 15px 0;
}
.team__item__buttons {
  margin-top: 30px;
}
/* FOOTER SECTION */
.footer__container {
  display: flex;
  justify-content: space-around;
  max-width: 1710px;
  margin-bottom: 60px;
  margin: 0 auto;
  padding: 0 30px;
  @media (max-width: 468px) {
    padding: 0 15px;
  }
  @media (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.footer__left {
  display: flex;
  & .get-started {
    max-width: 450px;
    color: #eeeff7;
    font-size: 20px;
    @media (max-width: 1280px) {
      max-width: 500px;
    }
  }
  & .footer__info {
    margin-bottom: 30px;
  }
}
.footer__title {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  @media (max-width: 468px) {
    font-size: 22px;
  }
}
.footer__links__container {
  display: flex;
  @media (max-width: 468px) {
    flex-direction: column;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
  }
  & .footer__links {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 110px;
    @media (max-width: 900px) {
      margin-top: 30px;
    }
    @media (max-width: 1100px) {
      margin-right: 30px;
    }
    @media (max-width: 1280px) {
      margin-right: 80px;
    }
    & a {
      transition: 0.5s all ease;
      margin-bottom: 5px;
      font-size: 20px;
      &:hover {
        transform: translateX(10px);
      }
    }
  }
}
.footer__end {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #090f32;
  position: relative;
  padding: 20px 30px;
  & img {
    height: 80px;
    margin-right: 15px;
  }
  & p {
    font-size: 20px;
    color: white;
  }
}
